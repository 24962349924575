<template>
    <div
        v-if="showWithoutParent === true && element.options_parent_question_guid !== null"
        class="text-neutral-800 italic"
    >
        {{ $t('PRINT.PREVIOUS_OPTIONS') }}
    </div>
    <div
        v-else
        class="inline-block w-auto mobile:block leading-none"
        :style="{ minWidth: '280px' }"
        :class="[answerFontSizeClass, optionsSpace]"
    >
        <answer-box
            v-for="(option, index) in visibleOptions"
            :key="index"
            :selected="values.includes(option.value)"
        >
            <div
                class="flex items-center"
            >
                <z-checkbox
                    :id="element.guid + option.index"
                    v-model="values"
                    :value="option.value"
                    :primary-color="primary_color"
                    :disabled="numberOfCheckins >= element.max_checkins && !values.includes(option.value)"
                    class="w-full"
                    :class="[paddingSize]"
                >
                    <span
                        :style="labelStyle"
                        v-html="option.optionText"
                    />
                </z-checkbox>
            </div>
        </answer-box>
        <answer-box
            v-if="element.has_other_option"
            class="other"
            :selected="other"
        >
            <div
                class="flex items-center"
            >
                <z-checkbox
                    :id="element.guid + 'other'"
                    v-model="other"
                    :disabled="(numberOfCheckins >= element.max_checkins && !other)"
                    :primary-color="primary_color"
                    :class="[paddingSize, {'w-full': !other }]"
                >
                    <span :style="labelStyle">
                        {{ otherOptionLabel }}
                    </span>
                </z-checkbox>
                <answer-box-input
                    v-if="other"
                    v-model="otherText"
                    :primary-color="primary_color"
                    :text-color="text_color"
                />
            </div>
        </answer-box>
        <answer-box
            v-if="element.has_dkna_option"
            class="dkna"
            :selected="dkna"
        >
            <div
                class="flex items-center"
            >
                <z-checkbox
                    :id="element.guid + 'dkna'"
                    v-model="dkna"
                    :primary-color="primary_color"
                    class="w-full"
                    :class="[paddingSize]"
                >
                    <span :style="labelStyle">
                        {{ dknaOptionLabel }}
                    </span>
                </z-checkbox>
            </div>
        </answer-box>
        <div
            v-if="showMaxCheckinsWarning"
            :style="labelStyle"
        >
            {{ $t('QUESTION.MAX_CHECKINS') }} {{ element.max_checkins }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useState, useActions, useGetters } from 'vuex-composition-helpers';
import ZCheckbox from '@/components/ui/VModelCheckbox.vue';
import AnswerBox from '@/components/ui/AnswerBox.vue';
import AnswerBoxInput from '../ui/AnswerBoxInput.vue';

import useOptions from './hooks/useOptions';
import useStyle, { useResponsiveFontSizeClass, useSpacingClass } from './hooks/useStyle';

/* Our goal here is to generate an array like this: [ true, false, false, ' ] */
export default defineComponent({
    components: {
        ZCheckbox,
        AnswerBox,
        AnswerBoxInput,
    },
    props: {
        element: { type: Object, required: true},
        mobileView: { type: Boolean, default: false},
        showWithoutParent: { type: Boolean, default: false },
    },
    setup(props) {
        const values = computed<number[]>({
            get: () => {
                const answer = answers.value[props.element.guid];

                if (!answer) return [];

                if (!Array.isArray(answer.value)) {
                    return visibleOptions.value.reduce((values, option) => {
                        if (answer.value[option.id]) {
                            values.push(option.id);
                        }

                        return values;
                    }, []);
                }

                return answer.value.reduce((values, option, index) => {
                    if (option) {
                        values.push(index);
                    }
                    return values;
                }, []);
            },
            set: (value) => {
                updateAnswer({ value, other: other.value ? otherText.value : undefined, dkna: false });
            },
        });
        const other = computed<boolean>({
            get: () => answers.value[props.element.guid]?.other != undefined,
            set: (val) => {
                if (val) {
                    updateAnswer({ other: '', dkna: false });
                }
                else {
                    updateAnswer({ other: null });
                }
            },
        });
        const otherText = computed<string>({
            get: () => answers.value[props.element.guid]?.other,
            set: (val) => {
                updateAnswer({ other: val });
            },
        });
        const dkna = computed<boolean>({
            get: () => Boolean(answers.value[props.element.guid]?.dkna),
            set: (val) => {
                if (val) {
                    updateAnswer({ dkna: true, value: [], other: undefined });
                }
                else {
                    updateAnswer({ dkna: false });
                }
            }
        });

        const { primary_color, text_color, background_color, answers, hasNewDictionary } = useState([
            'primary_color',
            'text_color',
            'background_color',
            'answers',
            'hasNewDictionary'
        ]);

        const { setAnswer } = useActions(['setAnswer']);

        const visibleOptions = useOptions(props.element);
        const { labelStyle } = useStyle();
        const { answerFontSizeClass } = useResponsiveFontSizeClass();

        const showMaxCheckinsWarning = computed(() => props.element.max_checkins < visibleOptions.value.length);

        const numberOfCheckins = computed(() => {
            let checkins = values.value.length;
            if (other.value) {
                checkins++;
            }
            if (dkna.value) {
                checkins++;
            }
            return checkins;
        });

        function transformValues(val: Array<number|string>) {
            let value;

            if (hasNewDictionary.value) {
                value = visibleOptions.value.reduce((vals, option) => {
                    return {
                        ...vals,
                        [option.id]: val.includes(option.value) ? 1 : 0
                    };
                }, {});
            }
            else {
                value = props.element.options.map((_, i) => val.includes(i) ? 1 : 0);
            }

            return value;
        }

        function updateAnswer(answer: { value?: any, other?: any, dkna?: any }) {
            setAnswer({
                guid: props.element.guid,
                answer: {
                    value: answer.value !== undefined ? transformValues(answer.value) : transformValues(values.value),
                    other: answer.other !== undefined ? answer.other : undefined,
                    dkna: answer.dkna !== undefined ? answer.dkna : dkna.value,
                }});
        }

        const { getTranslations } = useGetters(['getTranslations']);

        const otherOptionLabel = computed(() => {
            return getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.other_option_label || props.element.other_option_label;
        });

        const dknaOptionLabel = computed(() => {
            return getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.dkna_option_label || props.element.dkna_option_label;
        });

        const { paddingSize, optionsSpace } = useSpacingClass();

        return {
            values, otherText, other, dkna, // refs
            primary_color, text_color, background_color, // store state
            visibleOptions, labelStyle, answerFontSizeClass, showMaxCheckinsWarning, numberOfCheckins, // computed
            otherOptionLabel, dknaOptionLabel,
            paddingSize, optionsSpace,
        };
    },
});
</script>
