import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { Element } from '@/types/Element';
import { FillingProgressPosition, LayoutSpacing, QuestionTagType } from '@/types/SettingEnums';
import { autoPageState } from '@/store/autopage';
import { multiLangState } from '@/store/multilang';
import resumable from './resumableSurvey';
import { EmbeddedTypeEnum } from '@/enums/embeddedTypeEnum';

let isEmbedded;
try {
    isEmbedded = window.self !== window.top;
} catch (e) {
    isEmbedded = true;
}
// Check if the privacy policy is shown on the embed iframe, default to yes (no explicit hide)
const iframePp = window.location.search.indexOf('zpp=0') === -1;

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isEmbedded,
        isNewEmbedded: false,
        isNewEmbeddedPreview: false,
        iframePp,
        isPreviewMode: false,
        urlCode: null,
        id: null,
        title: '',
        description: '',
        created_at: null,
        creator: '',
        logo_file_url: null,
        logo_source_url: null,
        logo_settings: { hasLogo: true, position: 'top-center-above', size: 'small', onlyOnFirstPage: false },
        thank_you_logo: null,
        thank_you_logo_url: null,
        primary_color: '#1cc6ba',
        text_color: '#212529',
        background_color: '#ffffff',
        title_font_name: null,
        title_font_weight: null,
        title_font_size: 'text-3xl',
        is_question_ordinal_number_visible: null,
        question_tag_type: QuestionTagType.OPTIONAL,
        is_filling_time_visible: null,
        filling_time: 0,
        is_filling_progress_visible: true,
        filling_progress_position: FillingProgressPosition.TOP,
        question_font_name: null,
        question_font_weight: null,
        question_font_size: 'text-xl',
        answer_font_name: null,
        answer_font_weight: null,
        answer_font_size: 'text-lg',
        layout_spacing: LayoutSpacing.SPACIOUS,
        language_id: null,
        is_autosave_on: false,
        isAutosaveAccepted: false,
        isTest: false,
        elements: [] as Element[],
        answers: {},
        selectedAnswers: {}, // only for Scale Values questions
        seed: Math.random(),
        thank_you_title: '',
        thank_you_description: '',
        thank_you_image_url: '',
        isFinished: false,
        hasBeenFilled: false,
        status: 0,
        disqualificationPages: [],
        validationErrors: {},
        uniqueName: '',
        urlParameters: [],
        bgImage: null,
        shouldShowBgImageOnEmbed: true,
        isMultipleFillingOn: false,
        multiFillRestriction: 'any',
        multiFillRestrictionType: 'cookie',
        isIpCollectionOn: false,
        isAutosaveOn: false,
        password: null,
        responseLimit: null,
        dataSourceId: null,
        datasetId: null,
        hasOwnPrivacyPolicy: false,
        privacyPolicyTitle: null,
        privacyPolicyUrl: null,
        userId: null,
        recordId: null,
        startedAt: null,
        usedUrlParams: [],
        lastResponseAt: null,
        isFreeGroup: false,
        accessedFeatures: [],
        query: {
            p: 1,
            o: 0
        },
        lastVisitedPage: 0,
        thankYouRedirect: null,
        overallResponseLimitTarget: null,
        singleResponseLimitTarget: null,
        showPage: null,
        ...autoPageState,
        ...multiLangState,
        resumableSurveyFillingGuid: null,
        hasNewDictionary: null,
        embeddedType: null as null|EmbeddedTypeEnum,
        GTMId: null,
        googleAnalyticsId: null,
        fbPixelId: null
    },
    getters,
    mutations,
    actions,
    modules: {
        resumable
    }
});
